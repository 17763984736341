import React from "react"
import Layout from "../components/Layout/Layout"

export default function Sluzby() {
  return (
      <Layout>
<div className="container">
    <h1>Hello on Page 2</h1>
<p>I am basic Haxo Gatsby Template!</p>





</div>

      </Layout>
  );
}